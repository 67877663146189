import React from 'react'
import classNames from 'classnames'
import { CompassGroups, CompassKeys } from '@/types'

interface StoryCompassProps {
    compassKey: CompassGroups | CompassKeys | 'All';
    isLivingCities?: boolean;
}

export const StoryCompass = ({
    compassKey,
    isLivingCities,
}: StoryCompassProps) => {
    const isEcology = compassKey === 'All' || compassKey === 'Ecology' || compassKey === 'ecology'
    const isEnergy = compassKey === 'All' || compassKey === 'Energy' || compassKey === 'energy'
    const isEconomy = compassKey === 'All' || compassKey === 'Economy' || compassKey === 'economy'
    const isUrban = compassKey === 'All' || compassKey === 'Urban' || compassKey === 'urban'
    const isArchitecture = compassKey === 'All' || compassKey === 'Architecture' || compassKey === 'architecture'

    const strokeEcology = isLivingCities ? 'stroke-livingCitiesEcology' : 'stroke-ecology'
    const strokeEnergy = isLivingCities ? 'stroke-livingCitiesEnergy' : 'stroke-energy'
    const strokeEconomy = isLivingCities ? 'stroke-livingCitiesEconomy' : 'stroke-economy'
    const strokeUrban = isLivingCities ? 'stroke-livingCitiesUrban' : 'stroke-urban'
    const strokeArchitecture = isLivingCities ? 'stroke-livingCitiesArchitecture' : 'stroke-architecture'
    
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140" fill="none">
            <circle r="66" cx="70" cy="70" strokeWidth="8" className={ classNames(isEcology ? strokeEcology : 'stroke-white/30') } />
            <circle r="55" cx="70" cy="70" strokeWidth="8" className={ classNames(isEnergy ? strokeEnergy : 'stroke-white/30') } />
            <circle r="44" cx="70" cy="70" strokeWidth="8" className={ classNames(isEconomy ? strokeEconomy : 'stroke-white/30') } />
            <circle r="33" cx="70" cy="70" strokeWidth="8" className={ classNames(isUrban ? strokeUrban : 'stroke-white/30') } />
            <circle r="22" cx="70" cy="70" strokeWidth="8" className={ classNames(isArchitecture ? strokeArchitecture : 'stroke-white/30') } />
        </svg>
    )
}
