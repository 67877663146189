'use client'

import React from 'react'
import { useInView } from 'framer-motion'
import { usePrefersReducedMotion } from '@/utils/usePrefersReducedMotion'

type ReactVideoElementProps = React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>

interface VideoProps extends Omit<ReactVideoElementProps, 'ref'> {}

/**
 * Video component that plays/pauses videos when they move in/out of the viewport
 * 
 * Source elements should be provided as children e.g.
 * 
 * ```
 * <video>
 *   <source src="/videos/home-flax.webm" type="video/webm" />
 *   <source src="/videos/home-flax.mp4" type="video/mp4" />
 * </video>
 * ```
 * 
 * Useful commands to compress videos
 * ```
 *  ffmpeg -i architecture.mov -c:v libx264 -pix_fmt yuv420p -r 15 architecture.mp4;
 * 
 *  ffmpeg -i architecture.mp4 -c:v libvpx -quality good -cpu-used 0 -b:v 7000k -qmin 10 -qmax 42 -maxrate 500k -bufsize 1500k -threads 8 -vf scale=-1:1080 -c:a libvorbis -b:a 192k -f webm architecture.webm;
 * ```
 */
export const Video = ({ children, ...videoElementProps }: VideoProps) => {
    const prefersReducedMotion = usePrefersReducedMotion()
    const ref = React.useRef<HTMLVideoElement>(null)
    const inView = useInView(ref)
    
    // Play and pause the videos when they come into view
    React.useEffect(() => {
        if (inView && !prefersReducedMotion) {
            ref.current?.play()
        } else {
            ref.current?.pause()
        }
    }, [inView, prefersReducedMotion])

    // @TODO We should add a poster for the video, so we can show that and not load the video
    // if prefersReducedMotion is true

    // Disable autoplay if user prefers reduced motion
    return (
        <video
            ref={ ref }
            { ...videoElementProps }
            preload={ prefersReducedMotion ? "none" : "auto" }
            autoPlay={ !prefersReducedMotion }
            disablePictureInPicture
            disableRemotePlayback
            // poster=''
        >
            {children}
        </video>
    )
}
