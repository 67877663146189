import React from 'react'
import MapPinSVG from './map-pin.svg'
import { Accordion } from '@/components/UI/Accordion/Accordion'
import { Status } from '@/components/UI/Status/Status'
import Image from 'next/image'
import { CompassGraphDataV2, Indicator } from '@/types'

interface DataToolAccordionProps {
    title: string
    indicators: Indicator[]
    onIndicatorHover: (indicatorId: string) => void
    setSelectedIndicator: (indicator: { compassGroupId?: string; indicatorId?: string }) => void
    dataCompassPageUrl: string
    defaultIsOpen?: boolean
}

const DataToolAccordion = ({ title, indicators, onIndicatorHover, setSelectedIndicator, dataCompassPageUrl, defaultIsOpen }: DataToolAccordionProps) => {
    return (
        <div className="flex flex-col gap-4 rounded-lg bg-darkGreen-90">
            <Accordion
                title={ title }
                noBorder
                noPadding
                defaultIsOpen={ defaultIsOpen }
                className="p-4"
            >
                <div className="flex flex-col gap-2 px-4 pb-4">
                    {indicators.map(({label, status, slug: id, compassId}) => {
                        const indicatorUrl =  `${ dataCompassPageUrl }${ compassId }/${ id }/`

                        return (
                            <a
                                href={ indicatorUrl }
                                onClick={ (evt) => {
                                    evt.preventDefault()
                                    window.history.pushState({}, '', indicatorUrl)
                                    setSelectedIndicator({ compassGroupId: compassId, indicatorId: id})
                                } }
                                key={ id }
                                onMouseOver={ () => onIndicatorHover(id) }
                                onMouseLeave={ () => onIndicatorHover(null) }
                                className="rounded-xl border border-white/10 bg-white/5 px-3 py-4 transition-colors hover:bg-darkGreen-80"
                            >
                                <Status label={ label } status={ status } withChevron />
                            </a>
                        )
                    })}
                </div>
            </Accordion>
        </div>
    )
}

interface OverviewPanelProps {
    location: string
    // location_title_te_reo: string
    dataCompassPageUrl: string
    data: CompassGraphDataV2
    onIndicatorHover: DataToolAccordionProps['onIndicatorHover']
    setSelectedIndicator: (indicator: { compassGroupId?: string; indicatorId?: string }) => void
    highlightGroup?: string
}

export const OverviewPanel = ({dataCompassPageUrl, location, data, onIndicatorHover, setSelectedIndicator, highlightGroup}: OverviewPanelProps) => {
    return (
        <div className="flex flex-col gap-6">
            <div className="flex flex-row items-center gap-6 overflow-hidden rounded-lg bg-darkGreen-90 p-6">
                <Image src="/images/data-tool/compass-logo.png" alt="" width={ 191 } height={ 185 } className="max-h-[185px] w-1/2 max-w-[190px] md:w-3/5" />
                <h1 className="w-2/5 text-2xl leading-none"><span className="text-ecologyWhite">Mauri Ora</span> urban wellbeing Data Tool</h1>
            </div>

            <div className="flex flex-row items-center gap-3 rounded-lg border border-darkGreen-90 bg-darkGreen-100 px-6 py-4">
                <MapPinSVG className="size-6" />

                {/* {location_title_te_reo ? ` - ${ location_title_te_reo }` : ""} */}
                <p className="font-bold capitalize">{location}</p>
            </div>

            <div className="flex flex-col gap-4">
                {data.order.map((compassKey) => {
                    const indicators = data[compassKey]
                    const label = compassKey.charAt(0).toUpperCase() + compassKey.slice(1)

                    return (
                        <DataToolAccordion
                            key={ compassKey }
                            title={ label }
                            indicators={ indicators }
                            onIndicatorHover={ onIndicatorHover }
                            dataCompassPageUrl={ dataCompassPageUrl }
                            setSelectedIndicator={ setSelectedIndicator }
                            defaultIsOpen={ highlightGroup ? compassKey === highlightGroup : true }
                        />
                    )
                })}
            </div>
        </div>
    )
}
