import { CompassKeys } from "@/types"


export const COMPASS_KEY_TO_LABEL: Record<CompassKeys, string> = {
    'ecology': 'Ecology',
    'energy': 'Energy',
    'economy': 'Economy',
    'urban': 'Urban',
    'architecture': 'Architecture',
}

export const PORTAL_ID_FOR_INDICATOR_PANEL = 'indicator-portal'
