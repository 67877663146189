import(/* webpackMode: "eager" */ "/frontend/nextjs/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["DataCompassPage"] */ "/frontend/nextjs/src/components/Pages/DataCompassPage/DataCompassPage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HomePage"] */ "/frontend/nextjs/src/components/Pages/HomePage/HomePage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StoryPage"] */ "/frontend/nextjs/src/components/Pages/StoryPage/StoryPage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion"] */ "/frontend/nextjs/src/components/UI/Accordion/Accordion.tsx");
;
import(/* webpackMode: "eager" */ "/frontend/nextjs/src/components/UI/Button/Button.tsx");
;
import(/* webpackMode: "eager" */ "/frontend/nextjs/src/components/UI/RenderCmsImage/RenderCmsImage.tsx");
;
import(/* webpackMode: "eager" */ "/frontend/nextjs/src/components/UI/Video/Video.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WagtailUserBar"] */ "/frontend/nextjs/src/components/WagtailUserBar.tsx");
